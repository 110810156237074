html,
body {
  height: 100%;
}
body {
  margin: 0;
}
.flex-container {
  text-align: center;
}
