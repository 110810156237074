.svelte-jsoneditor-react {
  display: flex;
  flex: 1;
  width: 600px;
  height: 550px;
  font-family: "Roboto Condensed" !important;
}

.my-json-editor {
  /* define a custom theme color */
  --jse-theme-color: #3d58c1;
  --jse-theme-color-highlight: #3d58c1;
  --jse-value-color: #383e42;
  --jse-key-color: #383e42;

  --jse-value-color-number: #383e42;
  --jse-value-color-boolean: #383e42;
  --jse-value-color-null: #383e42;
  --jse-value-color-string: #383e42;
}

.jse-key,
.jse-value {
  font-family: "Roboto Condensed" !important;
  font-size: 1.1rem;
}

.jse-key {
  font-weight: 700;
}
