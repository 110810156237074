@import url(./css/xerox-indigo.css);

body {
  --background-color: #e5e5ea;
  background-color: #f2f2f9;
  font-family: "Roboto Condensed";
  padding-bottom: 0 !important;
}
.main-btn {
  position: fixed;
  bottom: 50px;
  right: 50px;
}

.document-photo {
  max-height: 800px;
}

@font-face {
  font-family: "xGlyph Regular";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/xGlyph-Regular.woff) format("woff"),
    url(./fonts/xGlyph-Regular.otf) format("opentype"),
    url(./fonts/xGlyph-Regular.ttf) format("truetype");
}

.xglyph-font {
  font-family: "xGlyph Regular";
  font-size: 25px;
  text-align: center;
  margin-bottom: 0;
}

#email-input {
  width: 350px;
}
#password-input {
  width: 350px;
}
#code-input {
  width: 300px;
}
.btn-login {
  width: 350px;
}
#xrx-input-email {
  width: 350px;
}
#xrx-input-name {
  width: 350px;
}
.message-warning {
  color: #d92231;
}

.login-ui {
  max-width: 350px;
  margin-top: 5px;
}
.xrx-login {
  margin-bottom: 25px;
  background-color: #c62929;
  color: white;
}

.tabView {
  background-color: red;

  height: 100%;
  width: 100%;
}

.data-two-column {
  background-color: cyan;
}

#mainBar {
  display: inline-flex;
  gap: 50px;
}

@media screen and (max-width: 600px) {
  #mainBar {
    display: inline-flex;
    gap: 1px;
  }
}

.xrx-blk {
  width: 350px;
}

.dashboard-graph-card {
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 25px;
}

button:not(:disabled):hover {
  background-color: none;
  opacity: 0.8 !important;
}
