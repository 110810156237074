#toolbarViewer {
  height: 32px;
  display: none !important;
}

.svelte-jsoneditor-react {
  height: 750px;
  width: 100%;
}

.block-demo {
  height: 60rem;
  margin: 2rem 0;
}

.document-photo {
  height: 650px;
  min-width: 400px;
  max-width: 600px;
  margin: 0 auto;
}

.rpv-core__viewer {
  padding-left: 2%;
  padding-right: 2%;
  margin: 0 auto;
}

.data-core-view {
  height: 850px !important;
  padding: 2%;
}
#summaryfields {
  max-height: 750px;
  overflow: scroll;
  font-size: 1.1rem;
  width: 98%;
}
#lineItems {
  max-height: 750px;
  overflow: scroll;
  font-size: 1.1rem;
  width: 98%;
}
#editItems {
  max-height: 750px;
  overflow: scroll;
  width: 98%;
}
.rpv-default-layout__sidebar-headers {
  display: none;
}

.rpv-toolbar__right > .rpv-toolbar__item > {
  display: none !important;
}

@media screen and (max-width: 1366px) {
  /* insert styles here */
  .block-demo {
    height: 40rem;
    margin: 2rem 0;
  }

  .data-core-view {
    height: 500px !important;
    padding: 1%;
  }
  .svelte-jsoneditor-react {
    height: 435px;
    width: 95%;
  }
  .document-photo {
    height: 500px;
  }
  #lineItems,
  #summaryfields {
    height: 475px;
    width: 95%;
  }
}

@media only screen and (max-height: 1200px) and (min-width: 600px) {
  .data-core-view {
    height: 600px !important;
    padding: 1%;
  }
  .svelte-jsoneditor-react {
    height: 475px;
    width: 95%;
  }
  .document-photo {
    height: 500px;
  }
  #lineItems,
  #summaryfields {
    height: 500px;
    width: 95%;
  }
}

@media screen and (max-width: 600px) {
  .rpv-core__viewer {
    font-size: 0.8rem !important;
  }
}
