/* body {
    padding: 5rem;
} */

table {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  border: 1px solid #3a3737;
}

/* thead {
    background-color: #333;
    color: white;   
} */

th,
td {
  padding: 5px;
  text-align: left;
  /* border-bottom: 1px solid grey;  */
}

tr:nth-child(even) {
  background: #ddd;
}

.container {
  margin: 20px auto;
  max-width: 800px;
}

a {
  text-decoration: underline;
}
